<template>
    <v-row no-gutters>
        <v-col cols="12" sm="5" md="5" lg="5" xl="5">
            <v-card class="mx-auto">
                <v-list :style="{ height: listHeight + 'px', 'overflow-y': 'scroll' }">
                    <span v-for="(item, i) in items" :key="i" :value="item">
                        <v-list-item color="primary" @click="addItem(item)">
                            <v-list-item-content>
                                <v-list-item-title>{{item[itemTitle]}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </span>
                </v-list>
            </v-card>
        </v-col>
        
        <v-col class="align-self-center" cols="12" sm="2" md="2" lg="2" xl="2">
            <div class="px-2">
                <v-btn class="my-1" block :color="btnColor" @click="removeAll">
                    <v-icon>{{removeAllIcon}}</v-icon>
                </v-btn>
                <v-btn class="my-1" block :color="btnColor" @click="addAll">
                    <v-icon>{{addAllIcon}}</v-icon>
                </v-btn>
            </div>
        </v-col>

        <v-col cols="12" sm="5" md="5" lg="5" xl="5">
            <v-card class="mx-auto">
                <v-list :style="{ height: listHeight + 'px', 'overflow-y': 'scroll' }"> 
                    <span v-for="(item, i) in itemsResult" :key="i" :value="item">
                        <v-list-item color="primary" @click="removeItem(item)">
                            <v-list-item-content>
                                <v-list-item-title>{{item[itemTitle]}}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </span>
                </v-list>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
// IMPORTS
// import ... from '...';

export default ({
    computed: {
        // Items to select list
        items: function() {
            return this.dataList.filter(f=> !this.resultList.some(s => s == f[this.itemValue]));
        },
        // Selected items list
        itemsResult: function() {
            return this.dataList.filter(f=> this.resultList.some(s => s == f[this.itemValue]));
        }
    },
    props: {
      // Data management
      value: { type: Array, default: () => [] },
      dataList: { type: Array, default: () => [] },
      itemTitle: { type: String, required: true },
      itemValue: { type: String, required: true },
      // Style configuration
      removeAllIcon: { type: String, default: 'mdi-chevron-double-left' },
      addAllIcon: { type: String, default: 'mdi-chevron-double-right' },
      listHeight: { type: Number, default: 500 },
      btnColor: { type: String, default: 'secondary' },
    },
    watch: {
        value: {
            immediate: true,
            handler () {
                this.resultList = this.value
            }
        }
    },
    data () {
        return {
            resultList: []
        }
    },
    methods: {
        // Click Events
        removeItem(item) {
            this.resultList = this.resultList.filter(f => f != item[this.itemValue]);
            this.emitInput(this.resultList);
            this.emitRemoved(item);
        },
        addItem(item) {
            this.resultList.push(item[this.itemValue]);
            this.emitInput(this.resultList);
            this.emitAdded(item);
        },
        removeAll() {
            this.resultList = [];
            this.emitInput(this.resultList);
            this.emitRemovedAll();
        },
        addAll() {
            this.resultList = this.dataList.map(m => m[this.itemValue]);
            this.emitInput(this.resultList);
            this.emitAddedAll();
        },
        // Emit Events
        emitInput(value)
        {
            this.$emit('input', value)
        },
        emitAdded(value)
        {
            this.$emit('added', value)
        },
        emitRemoved(value)
        {
            this.$emit('removed', value)
        },
        emitAddedAll()
        {
            this.emitAdded(null)
            this.$emit('added-all')
        },
        emitRemovedAll()
        {
            this.emitRemoved(null)
            this.$emit('removed-all')
        }
    } 
})
</script>
